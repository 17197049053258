import React, { useEffect, useState } from "react"

// Providers
import { useTheme } from "../context/ThemeContext"

// Hooks & Utils
import { UseShopifyDataQuery } from "../hooks/shopifyData"
import lazyLoad from "../utilities/handleLazyLoad"

// Data
import WORKOUT_PLANS_DATA from "../../static/data/products/workout-plans"
import MEAL_PLANS_DATA from "../../static/data/products/meal-plans"
import SUPPLEMENTS_DATA from "../../static/data/products/supplements"
import BUNDLES_DATA from "../../static/data/products/bundles"
import APPAREL_DATA from "../../static/data/apparel/apparel"
import MERCHANDISE_DATA from "../../static/data/apparel/merchandise"

// Components
import Loader from "../components/Loader/Loader"
import BannerSection from "../components/Banners/BannerSection"
import NavCardMenu from "../components/Navigation/NavCardMenu"
import ProductBoxSection from "../components/ProductBox/ProductBoxSection"
const Seo = lazyLoad(() => import("../components/Seo/Seo"))
const Testimonial = lazyLoad(() => import("../components/Sliders/Testimonial"))
const RockYourLifeBox = lazyLoad(() => import("../components/Box/RockYourLifeBox"))
const ScrollTopButton = lazyLoad(() => import("../components/Scrollable/ScrollTopButton"))
const ModalQuickBuy = lazyLoad(() => import("../components/Modals/ModalQuickBuy"))
const ModalSizeGuide = lazyLoad(() => import("../components/Modals/ModalSizeGuide"))

const Home = () => {
  const shopifyData = UseShopifyDataQuery()
  const { pageLoader, shipping, country } = useTheme()

  /*============================
   CUSTOM GTM EVENT
  =============================*/

  useEffect(() => {
    // set custom gtm event
    if (typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "pageview",
      })
    }
  }, [])

  /*============================
   QUICK BUY MODAL
  =============================*/
  const [isQuickBuyModalOpen, setIsQuickBuyModalOpen] = useState(false)

  const [shopifyItem, setShopifyItem] = useState("")
  const [clickedProduct, setClickedProduct] = useState(false)
  const [selectedProductColor, setSelectedProductColor] = useState("")

  // Set the shopify data for the selected product
  const handleShopify = item => setShopifyItem(item)

  // Set the {product/apparel}Data for the selected product
  const handleProduct = product => setClickedProduct(product)

  // Set the selected product color
  const handleSelectedProductColor = color => setSelectedProductColor(color)

  // Add/remove overflow hidden when modal is opened/closed
  useEffect(() => {
    if (isQuickBuyModalOpen) {
      document.body.classList.add("overflow--hidden")
    } else {
      document.body.classList.remove("overflow--hidden")
    }
  }, [isQuickBuyModalOpen])

  /*============================
   SIZE GUIDE MODAL
  =============================*/
  const [isModalSizeGuideOpen, setIsModalSizeGuideOpen] = useState(false)

  return (
    <>
      {/*loader*/}
      <Loader isLoader={pageLoader} />

      {/*page title and og share*/}
      <Seo
        pageTitle="The Betty Rocker"
        title="The Betty Rocker"
        description="Whole food recipes and Home Workouts for Your Healthy Lifestyle of Awesome!"
        url="https://store.thebettyrocker.com/"
        imgSrc="https://store.thebettyrocker.com/images/fb-share/betty-rocker-store.png"
      />

      {/*hero*/}
      <BannerSection type="homepage" country={country} />

      {/*nav cards*/}
      <NavCardMenu shipping={shipping} />

      {/*product box - WORKOUT PLANS*/}
      <ProductBoxSection
        title="workout plans"
        type="workout plan"
        productData={WORKOUT_PLANS_DATA}
        shopifyData={shopifyData}
        handleOpenQuickBuyModal={() => setIsQuickBuyModalOpen(true)}
        handleProduct={handleProduct}
        handleShopify={handleShopify}
        aboveTheFold={true}
      />

      {/*rock your life*/}
      <RockYourLifeBox />

      {/*product box - MEAL PLANS*/}
      <ProductBoxSection
        title="meal plans"
        type="meal plan"
        productData={MEAL_PLANS_DATA}
        shopifyData={shopifyData}
        handleOpenQuickBuyModal={() => setIsQuickBuyModalOpen(true)}
        handleProduct={handleProduct}
        handleShopify={handleShopify}
      />

      {/*product box - SUPPLEMENTS && BUNDLES*/}
      {shipping && (
        <>
          <ProductBoxSection
            title="bundles"
            type="bundles"
            productData={BUNDLES_DATA}
            shopifyData={shopifyData}
            handleOpenQuickBuyModal={() => setIsQuickBuyModalOpen(true)}
            handleProduct={handleProduct}
            handleShopify={handleShopify}
            modifierClass="bg--lightgray"
          />

          <section className="bg--lightgray pt--0 pb--30">
            <div className="container container--xxxl">
              <div className="arrow-separator arrow-separator--gray" />
            </div>
          </section>

          <ProductBoxSection
            title="supplements"
            type="supplements"
            productData={SUPPLEMENTS_DATA}
            shopifyData={shopifyData}
            handleOpenQuickBuyModal={() => setIsQuickBuyModalOpen(true)}
            handleProduct={handleProduct}
            handleShopify={handleShopify}
            modifierClass="bg--lightgray"
          />
        </>
      )}

      {/*product box - MERCHANDISE*/}
      {shipping && (
        <ProductBoxSection
          title="merchandise"
          type="merchandise"
          productData={MERCHANDISE_DATA}
          shopifyData={shopifyData}
          handleOpenQuickBuyModal={() => setIsQuickBuyModalOpen(true)}
          handleProduct={handleProduct}
          handleShopify={handleShopify}
        />
      )}

      {/*product box - APPAREL*/}
      {shipping && (
        <ProductBoxSection
          title="apparel"
          type="apparel"
          productData={APPAREL_DATA}
          shopifyData={shopifyData}
          handleOpenQuickBuyModal={() => setIsQuickBuyModalOpen(true)}
          handleProduct={handleProduct}
          handleShopify={handleShopify}
          handleSelectedProductColor={handleSelectedProductColor}
          modifierClass="bg--lightgray"
        />
      )}

      {/*testimonials*/}
      <Testimonial type="home" shipping={shipping} />

      {/*scroll to top button*/}
      <ScrollTopButton isSupplement={false} handleActiveTab={() => {}} isButtonOnly={true} tabs={[]} />

      {isQuickBuyModalOpen && (
        <ModalQuickBuy
          handleCloseQuickBuyModal={() => setIsQuickBuyModalOpen(false)}
          shopifyProduct={shopifyItem}
          product={clickedProduct}
          handleOpenModalSizeGuide={() => setIsModalSizeGuideOpen(true)}
          selectedProductColor={selectedProductColor}
          isCart={false}
        />
      )}

      {isModalSizeGuideOpen && (
        <ModalSizeGuide shopifyProduct={shopifyItem} handleCloseModalSizeGuide={() => setIsModalSizeGuideOpen(false)} />
      )}
    </>
  )
}

export default Home
